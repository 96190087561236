import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from 'core/components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSupplementContainer } from './SupplementPageContainer';

const BUTTON_WIDTH = 90;

const StyledButton = styled(Button)({ minWidth: BUTTON_WIDTH });
const Row = styled(Box)({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' });
const ProgressButtons = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	columnGap: theme.spacing(1),
}));

interface SupplementStepFooterProps {
	closeLabel?: string;
	nextLabel: string;
	// Only use nextDisabled if you also provide a reason why it's disabled
	// such as with a tooltip or Alert component
	nextDisabled?: boolean;
	onNext: () => void | Promise<void>;
	previousPath?: string;
}

export default function SupplementStepFooter({
	closeLabel,
	nextLabel,
	nextDisabled,
	onNext,
	previousPath,
}: SupplementStepFooterProps) {
	const { t } = useTranslation('irp/supplements');
	const navigate = useNavigate();
	const { closePath } = useSupplementContainer();

	return (
		<Row>
			<StyledButton variant="outlined" onClick={() => navigate(closePath)}>
				{closeLabel || t('buttons.close', { ns: 'core' })}
			</StyledButton>
			<ProgressButtons>
				{previousPath && (
					<StyledButton variant="outlined" onClick={() => navigate(previousPath)}>
						{t('buttons.previous', { ns: 'core' })}
					</StyledButton>
				)}
				<LoadingButton variant="contained" disabled={nextDisabled} sx={{ minWidth: BUTTON_WIDTH }} onClick={onNext}>
					{nextLabel}
				</LoadingButton>
			</ProgressButtons>
		</Row>
	);
}
