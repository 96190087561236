import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface CertifyCheckboxProps {
	name: string;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	error?: boolean;
}
export default function CertifyCheckbox({ name, checked, onChange, error }: CertifyCheckboxProps) {
	const { t } = useTranslation();
	return (
		<FormControl error={error}>
			<FormControlLabel
				control={<Checkbox name={name} checked={checked} onChange={onChange} />}
				label={t('data.certification')}
			/>
			<FormHelperText sx={{ ml: 4 }}>{t('data.validation.required')}</FormHelperText>
		</FormControl>
	);
}
