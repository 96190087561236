/* eslint-disable react/jsx-props-no-spreading */
import { AutocompleteProps, Divider, MenuItem, TextField } from '@mui/material';
import FormControl, { FormControlProps } from 'core/components/FormControl';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from './FormAutocomplete';

export type FormSelectOption<V = object> = V & {
	divider?: boolean;
};

export interface FormSelectFieldProps<T> extends FormControlProps {
	value: T | null;
	options: T[];
	onChange: (value: T | null) => void;
	getOptionLabel: (option: T) => string;
	getOptionKey: (option: T) => string | number;
	slotProps: FormControlProps['slotProps'] & {
		autocomplete?: Omit<
			AutocompleteProps<T, false, boolean, false>,
			'onChange' | 'onInputChange' | 'filterOptions' | 'options' | 'value' | 'loading' | 'renderInput'
		>;
	};
}

export default function FormSelectField<T extends FormSelectOption>({
	name,
	label,
	helperText,
	tooltip,
	value,
	options,
	onChange,
	getOptionLabel,
	getOptionKey,
	slotProps,
}: FormSelectFieldProps<T>) {
	const { t } = useTranslation();

	return (
		<FormControl name={name} label={label} helperText={helperText} tooltip={tooltip} slotProps={slotProps}>
			<Autocomplete
				noOptionsText={t('data.select_option', { ns: 'core' })}
				renderInput={(params) => (
					<TextField {...params} InputProps={params.InputProps} error={slotProps.formControl?.error} />
				)}
				getOptionLabel={getOptionLabel}
				getOptionKey={getOptionKey}
				isOptionEqualToValue={(opt, v) => getOptionKey(opt) === getOptionKey(v)}
				renderOption={(props, opt) => {
					return opt.divider ? (
						<Divider key={getOptionKey(opt)} />
					) : (
						<MenuItem {...props}>{getOptionLabel(opt)}</MenuItem>
					);
				}}
				openOnFocus
				autoSelect
				autoHighlight
				{...slotProps.autocomplete}
				options={options}
				value={value}
				onChange={(_, newValue) => onChange(newValue)}
			/>
		</FormControl>
	);
}
