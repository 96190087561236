import { HelpOutline } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { styled, useScrollTrigger, useTheme } from '@mui/material';
import MaterialAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactComponent as IterisLogo } from 'core/images/iteris-logo.svg';
import { Module } from 'core/types/module';
import RazorPaths from 'modules/razor/paths';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import AppBarUser from './AppBarUser';

// Styled dropdown menu
const DropdownMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		borderTopColor: 'transparent',
		backgroundColor: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
		minWidth: 220,
		color: theme.navbar?.color?.inverted || theme.palette.primary.main,
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
	},
}));

export interface AppBarProps {
	open: boolean;
	modules: Module[];
	helpRoute?: string;
	scrollRef?: React.RefObject<HTMLElement>;
	logoComponent?: JSX.Element;
	setOpen: (open: boolean) => void;
	getUserType(): Promise<string>;
}

function AppBar({ open, modules, helpRoute, scrollRef, logoComponent, setOpen, getUserType }: AppBarProps) {
	// Filter out only modules that have a nav menu
	const navModules = modules.filter((m) => !!m.nav);

	// Hooks
	// Load translations for *all* modules
	const { t } = useTranslation(navModules.map((m) => m.id));
	const theme = useTheme();
	const navigate = useNavigate();
	const isScrolled = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: scrollRef?.current || window,
	});

	// State
	const [openMenu, setOpenMenu] = React.useState<null | Module>(null);
	const [openMenuEl, setOpenMenuEl] = React.useState<null | HTMLElement>(null);

	const openNavMenu = (page: string) => (event: React.MouseEvent<HTMLElement>) => {
		setOpenMenuEl(event.currentTarget);
		setOpenMenu(navModules.find((button) => button.id === page) || null);
	};

	const closeNavMenu = () => {
		setOpenMenuEl(null);
		setTimeout(() => setOpenMenu(null), 500); // delay to allow menu to close
	};

	const navigateToModule = (module: string) => () => {
		const route = navModules.find((m) => m.id === module)?.routes?.[0];
		if (!route || !route.path) return;

		navigate(route.path);
	};

	return (
		<MaterialAppBar
			elevation={isScrolled ? 6 : 1}
			sx={{
				backgroundColor: theme.navbar?.backgroundColor?.main || theme.palette.primary.main,
			}}
		>
			<Toolbar sx={{ pl: 3 }} disableGutters>
				<Box sx={theme.navbar?.mode === 'horizontal' ? { display: { xs: 'flex', md: 'none' } } : undefined}>
					<IconButton
						aria-label="menu-icon"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						sx={{ ml: -1.5, mr: 1 }}
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<MenuOpenIcon
								sx={{ color: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText }}
							/>
						) : (
							<MenuIcon sx={{ color: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText }} />
						)}
					</IconButton>
				</Box>

				{/* Menu Icon & Logo */}
				<Box display="flex" sx={{ flexGrow: { xs: 1, md: 0 }, mr: 1 }}>
					<Link to={RazorPaths.buildPath({})}>{logoComponent || <IterisLogo height={28} />}</Link>
				</Box>

				{/* Desktop Modules Menu */}
				{theme.navbar?.mode === 'horizontal' ? (
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: 'none', md: 'flex' },
							justifyContent: 'flex-end',
							alignSelf: 'stretch',
						}}
					>
						{navModules.map((btn) => (
							<Button
								key={btn.id}
								sx={{
									px: 2,
									alignSelf: 'stretch',
									borderRadius: 0,
									color:
										openMenu === btn
											? theme.navbar?.backgroundColor?.main || theme.palette.primary.main
											: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
									backgroundColor:
										openMenu === btn
											? theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText
											: 'transparent',
									textTransform: 'none',
									':hover': {
										backgroundColor: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
										color: theme.navbar?.backgroundColor?.main || theme.palette.primary.main,
									},
								}}
								endIcon={btn.nav && btn.nav.length > 0 && <KeyboardArrowDownIcon />}
								onClick={btn.nav && btn.nav.length > 0 ? openNavMenu(btn.id) : navigateToModule(btn.id)}
							>
								{t(`title`, { ns: btn.id, defaultValue: `module:${btn.id}.title` })}
							</Button>
						))}

						<DropdownMenu
							anchorEl={openMenuEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: openMenu === navModules[navModules.length - 1] ? 'right' : 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: openMenu === navModules[navModules.length - 1] ? 'right' : 'left',
							}}
							keepMounted
							open={Boolean(openMenuEl)}
							onClose={closeNavMenu}
						>
							{openMenu?.nav?.map((item) => (
								<MenuItem
									key={item.id}
									onClick={() => {
										if (item.to) navigate(item.to);
										if (item.onClick) item.onClick();

										closeNavMenu();
										setOpen(false);
									}}
								>
									<Typography textAlign="center">
										{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
										{/* @ts-ignore */}
										{item.label || t(`navbar.${item.id}`, { ns: openMenu.id })}
									</Typography>
								</MenuItem>
							))}
						</DropdownMenu>
					</Box>
				) : (
					<Box flex={1} />
				)}

				{/* Help Icon */}
				{helpRoute && (
					<Button
						sx={{
							px: 2,
							alignSelf: 'stretch',
							borderRadius: 0,
							color: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
							textTransform: 'none',
							':hover': {
								backgroundColor: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
								color: theme.navbar?.backgroundColor?.main || theme.palette.primary.main,
							},
						}}
						onClick={() => navigate(helpRoute)}
					>
						<HelpOutline />
					</Button>
				)}

				{/* Current User Menu */}
				<AppBarUser getUserType={getUserType} />
			</Toolbar>
		</MaterialAppBar>
	);
}

export default AppBar;
