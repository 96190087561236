import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Title from 'core/components/Title';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import UserPaths from './paths';

const UserAdminDetail = lazy(() => import('./Detail'));
const ManagePermissions = lazy(() => import('./ManagePermissions'));
const UserAdmin = lazy(() => import('./UserAdmin'));

// Route component mappings
const UsersRoutes = [
	{
		path: UserPaths.path,
		element: (
			<>
				<Title
					title={(t) => `${t('title', { ns: 'admin/users' })} - ${t('title', { ns: 'core' })}`}
					ns={['admin/users']}
				/>
				<Outlet />
			</>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'admin/users' }),
		},
		children: [
			{
				path: UserPaths.path,
				index: true,
				element: (
					<CanAccess resource="admin.users" action={Actions.READ} denied={<NoPermission />}>
						<UserAdmin />
					</CanAccess>
				),
			},
			{
				path: UserPaths.Create.path,
				handle: {
					crumb: ({ t }) => t('users.add_new_user', { ns: 'admin/users' }),
				},
				element: (
					<CanAccess resource="admin.users" action={Actions.CREATE} denied={<NoPermission />}>
						<UserAdminDetail />
					</CanAccess>
				),
			} as Route<typeof UserPaths.Create>,
			{
				path: UserPaths.User.Edit.path,
				handle: {
					crumb: ({ t }) => t('users.account_details', { ns: 'admin/users' }),
				},
				element: (
					<CanAccess resource="admin.users" action={Actions.UPDATE} denied={<NoPermission />}>
						<UserAdminDetail />
					</CanAccess>
				),
			} as Route<typeof UserPaths.User.Edit>,
			{
				path: UserPaths.User.path,
				handle: {
					crumb: ({ t }) => t('users.account_details', { ns: 'admin/users' }),
				},
				element: (
					<CanAccess resource="admin.users" action={Actions.READ} denied={<NoPermission />}>
						<UserAdminDetail readOnly />
					</CanAccess>
				),
			} as Route<typeof UserPaths.User>,
			{
				path: UserPaths.ManagePermissions.path,
				handle: {
					crumb: ({ t }) => t('permissions.title', { ns: 'admin/users' }),
				},
				element: (
					<CanAccess resource="admin.users" action={Actions.WRITE} denied={<NoPermission />}>
						<ManagePermissions />
					</CanAccess>
				),
			} as Route<typeof UserPaths.ManagePermissions>,
		],
	} as Route<typeof UserPaths>,
];

export default UsersRoutes;
