import DialogContentText from '@mui/material/DialogContentText';
import Dialog from 'core/components/Dialog';
import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import { VehicleProvider } from 'modules/irp/modules/vehicles/providers/VehicleProvider';
import { VehicleIncludeFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function AddVehicleContainer({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/add_vehicle');
	const { supplementKey, vehicleKey } = useTypedParams(AddVehiclePaths.Vehicle);

	const includes: VehicleIncludeFields = useMemo(() => ({ weightGroup: true }), []);

	const isAddVehicleWorkflow = (pathname: string) =>
		pathname.startsWith(`${AddVehiclePaths.Vehicle.buildPath({ supplementKey, vehicleKey })}/`);

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (nextLocation.state?.bypass) return false;

		return isAddVehicleWorkflow(currentLocation.pathname) && !isAddVehicleWorkflow(nextLocation.pathname);
	});

	return (
		<>
			<VehicleProvider vehicleKey={vehicleKey} includes={includes}>
				{children}
			</VehicleProvider>

			<Dialog
				title={t('dialogs.close_detail.title')}
				severity="error"
				maxWidth="xs"
				isOpen={blocker.state === 'blocked'}
				setIsOpen={() => blocker.reset && blocker.reset()}
				confirmLabel={t('buttons.close', { ns: 'core' })}
				cancelLabel={t('buttons.stay', { ns: 'core' })}
				onConfirm={() => blocker.proceed && blocker.proceed()}
			>
				<DialogContentText>{t('dialogs.close_detail.description')}</DialogContentText>
			</Dialog>
		</>
	);
}
