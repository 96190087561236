import { Box, Button, Card, CardContent, Grid, InputLabel, Typography } from '@mui/material';
import FormInputField from 'core/components/FormInputField';
import { RazorSessionRoutes } from 'core/providers/RazorProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

export default function AccountLookup() {
	const { t } = useTranslation('accounts');
	const [sessionRoutes] = useTypedSearchParams(RazorSessionRoutes);

	const [_, setSearchParams] = useSearchParams();

	// TODO use formik once we get a design for this form
	const [value, setValue] = useState('');

	// Search the account by changing the URL
	function searchAccount() {
		const params: Record<keyof typeof sessionRoutes, string> = { accountKey: value };
		setSearchParams(params);
	}

	return (
		<Box display="flex" flex={1} alignItems="center" justifyContent="center">
			<Grid container maxWidth="sm">
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h3" gutterBottom>
								{t('lookup.title')}
							</Typography>
							<Typography>{t('lookup.instructions')}</Typography>

							<Box mt={2}>
								<Box display="flex" gap={2}>
									<FormInputField
										name="accountKey"
										// label={t('carrier.account_number', { ns: 'data' })}
										label="Account Key (UUID)"
										slotProps={{
											input: {
												value,
												onChange: (e) => setValue(e.target.value),
												onKeyUp: (e) => {
													if (e.key === 'Enter') searchAccount();
												},
											},
										}}
									/>

									{/* TODO improve this to not use dummy elements */}
									<Box display="flex" flexDirection="column">
										<InputLabel
											sx={{
												transform: 'none',
												display: 'flex',
												alignItems: 'center',
												gap: 1,
												whiteSpace: 'normal',
												mb: 1,
											}}
											shrink
										>
											&nbsp;
										</InputLabel>
										<Button variant="contained" color="primary" sx={{ flex: 1 }} onClick={() => searchAccount()}>
											{t('buttons.search', { ns: 'core' })}
										</Button>
									</Box>
								</Box>
							</Box>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
