import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import { string } from 'yup';

// F80B7221-35DC-EC11-B656-0003FF56C839
const uuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

const AccountPaths = route(
	'accounts',
	{},
	{
		Create: route('create', {
			searchParams: {
				userKey: yup(string().matches(uuid)).defined(),
				accountKey: yup(string().matches(uuid)),
			},
		}),
	},
);

export default AccountPaths;
