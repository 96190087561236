import { useTitle } from 'core/hooks';
import { Namespace, TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export interface TitleProps {
	title: (t: TFunction<Namespace>) => string;
	ns?: Namespace;
	prevailOnUnmount?: boolean;
}

export default function Title({ title, ns, prevailOnUnmount }: TitleProps) {
	const { t } = useTranslation(ns);

	const computedTitle = title instanceof Function ? title(t) : title;
	useTitle(computedTitle, prevailOnUnmount);

	return null;
}
