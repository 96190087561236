import { Breadcrumbs, CanAccess, PageContainer } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import RazorPaths from 'modules/razor/paths';
import { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import AccountPaths from './paths';

const AccountCreate = lazy(() => import('./AccountCreate'));

// Route component mappings
const AccountsRoutes = [
	{
		path: AccountPaths.path,
		element: (
			<CanAccess resource="accounts" action={Actions.READ} denied={<NoPermission />}>
				<PageContainer>
					<Breadcrumbs ns={['accounts']} />
					<Outlet />
				</PageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'accounts' }),
		},
		children: [
			// Index route
			{
				path: AccountPaths.path,
				index: true,
				element: <Redirect to={RazorPaths.buildPath({})} />,
			},

			// Create Account
			{
				path: AccountPaths.Create.path,
				element: (
					<ErrorBoundary fallback={<Redirect to={AccountPaths.buildPath({})} />}>
						<Outlet />
					</ErrorBoundary>
				),
				handle: {
					crumb: ({ t }) => t('create.title', { ns: 'accounts' }),
				},
				children: [
					{
						index: true,
						path: AccountPaths.Create.path,
						element: <AccountCreate />,
					} as Route<typeof AccountPaths.Create>,
				],
			} as Route<typeof AccountPaths.Create>,
		],
	} as Route<typeof AccountPaths>,
];

export default AccountsRoutes;
