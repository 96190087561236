/* eslint-disable react/jsx-props-no-spreading */
import { InputBaseProps, TextFieldProps, styled } from '@mui/material';
import { DatePickerProps, DateValidationError, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import FormControl, { FormControlProps } from 'core/components/FormControl';
import { DateFormat } from 'core/services/intl';
import dayjs from 'dayjs';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputBase from './InputBase';

export interface FormDateFieldProps extends FormControlProps {
	getError?: (err: DateValidationError, value: dayjs.Dayjs | null) => string | null;
	slotProps: FormControlProps['slotProps'] & {
		datePicker: DatePickerProps<dayjs.Dayjs>;
	};
}

const DatePicker = styled(MuiDatePicker)(({ theme }) => ({
	'& .MuiInputBase-root': {
		backgroundColor: '#f4f6f9',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#e7e9ec',
			transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
		},
	},
	'&.Mui-readOnly': {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	},
	'& .MuiInputBase-input': {
		padding: theme.spacing(1.25, 1.5),
		transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),

		'&.MuiInputBase-inputAdornedStart': {
			paddingLeft: 0,
		},
		'&.Mui-disabled': {
			cursor: 'not-allowed',
		},
		'&.Mui-readOnly': {
			cursor: 'default',
			paddingLeft: 0,
			paddingRight: 0,
			WebkitTextFillColor: theme.typography.subtitle2.color,
		},
	},
	'.MuiInputAdornment-root': {
		marginTop: '0 !important',
	},
})) as typeof MuiDatePicker;

const TextField = forwardRef(function TextField(props: TextFieldProps, ref: React.Ref<HTMLDivElement>) {
	const { InputProps, value, placeholder, sx, focused: _, error: __, children: ___, ...rest } = props;
	return (
		<InputBase
			ref={ref}
			value={value}
			placeholder={placeholder}
			sx={sx}
			{...(rest as InputBaseProps)}
			{...InputProps}
		/>
	);
});

export default function FormDateField({ name, label, helperText, tooltip, getError, slotProps }: FormDateFieldProps) {
	// Hooks
	const { t } = useTranslation();

	// State
	const [error, setError] = useState<{ error: DateValidationError; value: dayjs.Dayjs | null } | null>(null);

	// Compute error message translation
	const getErrorText: () => null | string = () => {
		if (!error) return null;
		if (getError && getError(error.error, error.value)) return getError(error.error, error.value);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return t(`data.validation.datepicker.${error?.error}`, {
			ns: 'core',
			date: error?.value?.format(DateFormat),
			minDate: slotProps.datePicker.minDate ? slotProps.datePicker.minDate.format(DateFormat) : '',
			maxDate: slotProps.datePicker.maxDate ? slotProps.datePicker.maxDate.format(DateFormat) : '',
		});
	};

	const errorText = error && getErrorText();

	return (
		<FormControl
			name={name}
			label={label}
			helperText={errorText || helperText}
			tooltip={tooltip}
			slotProps={{
				formControl: {
					...slotProps.formControl,
					error: !!error || slotProps.formControl?.error,
				},
				inputLabel: slotProps.inputLabel,
			}}
		>
			<DatePicker
				{...slotProps.datePicker}
				slots={{
					textField: TextField,
				}}
				slotProps={{
					...slotProps.datePicker.slotProps,
					field: {
						...slotProps.datePicker.slotProps?.field,
					},
				}}
				onError={(err, value) => {
					setError(err === null ? null : { error: err, value });
				}}
			/>
		</FormControl>
	);
}
