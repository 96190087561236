import { Typography } from '@mui/material';
import { PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import { useTranslation } from 'react-i18next';

export default function Home() {
	const { t } = useTranslation('home');

	useTitle(`${t('title', { ns: 'home' })} - ${t('title', { ns: 'core' })}`);

	return (
		<PageContainer>
			<Typography variant="h2">{t('title', { ns: 'core' })}</Typography>
		</PageContainer>
	);
}
