import WeightGroupChangePaths from 'modules/irp/modules/supplements/modules/weight_group_change/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function WeightGroupChangeSteps() {
	const { t } = useTranslation(['irp/supplements/weight_group_change', 'irp/supplements']);

	const { supplementKey } = useTypedParams(WeightGroupChangePaths);

	const steps = [
		{ label: t('details.step'), route: WeightGroupChangePaths.Details.buildPath({ supplementKey }) },
		{
			label: t('documentation.step', { ns: 'irp/supplements' }),
			route: WeightGroupChangePaths.Documentation.buildPath({ supplementKey }),
		},
		{
			label: t('verify.step', { ns: 'irp/supplements' }),
			route: WeightGroupChangePaths.Verify.buildPath({ supplementKey }),
		},
		{
			label: t('submit.step', { ns: 'irp/supplements' }),
			route: WeightGroupChangePaths.Submit.buildPath({ supplementKey }),
		},
	];

	return <SupplementSteps steps={steps} />;
}
