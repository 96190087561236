import { AccountCircle, Apps } from '@mui/icons-material';
import { Button, Divider, ListItemButton, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useConfig } from 'core/hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';

// Styled dropdown menu
const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		borderTopColor: 'transparent',
		backgroundColor: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
		minWidth: 220,
		transform: 'translate(16px, 0px) !important',
		color: theme.navbar?.color?.inverted || theme.palette.primary.main,
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
	},
}));

interface AppBarUserProps {
	getUserType(): Promise<string>;
}

function AppBarUser({ getUserType }: AppBarUserProps) {
	// Hooks
	const { t } = useTranslation();
	const theme = useTheme();
	const { user, signoutRedirect } = useAuth();
	const config = useConfig();
	const navigate = useNavigate();

	// State
	const [openMenuEl, setOpenMenuEl] = React.useState<null | HTMLElement>(null);
	const [userType, setUserType] = React.useState<string | null>(null);
	const [logoutConfirm, setLogoutConfirm] = React.useState(false);

	// Load user type
	React.useEffect(() => {
		getUserType().then(setUserType);
	}, [getUserType]);

	const openUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setOpenMenuEl(event.currentTarget);
	};

	const closeUserMenu = () => {
		setOpenMenuEl(null);
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 0, alignSelf: 'stretch' }}>
			<Button
				sx={{
					alignSelf: 'stretch',
					borderRadius: 0,
					columnGap: 1,
					color: openMenuEl
						? theme.navbar?.backgroundColor?.main || theme.palette.primary.main
						: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
					backgroundColor: openMenuEl
						? theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText
						: 'transparent',
					textTransform: 'none',
					':hover': {
						backgroundColor: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText,
						color: theme.navbar?.backgroundColor?.main || theme.palette.primary.main,
					},
				}}
				onClick={openUserMenu}
			>
				<AccountCircle />
			</Button>

			<StyledMenu
				anchorEl={openMenuEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				translate="no"
				open={Boolean(openMenuEl)}
				onClose={closeUserMenu}
			>
				{/* User info */}
				<Typography variant="subtitle2" sx={{ my: 1, px: 2 }}>
					{userType || t('data.loading', { ns: 'core' })}
				</Typography>
				<Typography sx={{ px: 2 }}>{user?.profile.email}</Typography>

				<MenuItem onClick={() => setLogoutConfirm(true)} dense>
					<Typography variant="subtitle2" sx={{ my: 0.5 }}>
						{t('buttons.logout', { ns: 'core' })}
					</Typography>
				</MenuItem>

				<Divider sx={{ my: 1, mx: 2 }} />

				{config.userMenuItems?.map((item, idx) => {
					// eslint-disable-next-line react/no-array-index-key
					if (item === 'divider') return <Divider key={`divider-${idx}`} sx={{ my: 1, mx: 2 }} />;

					const onClick = () => {
						// Do we have an onClick handler?
						if (item.onClick) item.onClick();

						// Navigate to path
						if (item.to) navigate(item.to);

						closeUserMenu();
					};

					return (
						<ListItemButton
							key={item.id}
							component={RouterLink}
							to={item.to || ''}
							target={item.to?.indexOf('http') === 0 ? '_blank' : undefined}
							sx={{ py: 0.5 }}
							onClick={onClick}
						>
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore */}
							<Typography>{item.label || t(`navbar.${item.id}`)}</Typography>
						</ListItemButton>
					);
				})}

				<Divider sx={{ my: 1, mx: 2 }} />

				{/*
				<Typography sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 1, px: 2 }}>
					<Apps sx={{ mr: 1 }} />
					My Applications
				</Typography>
				*/}

				<MenuItem onClick={closeUserMenu} dense>
					<Apps sx={{ mr: 1 }} />
					<Typography>{t('navbar.my_apps', { ns: 'core' })}</Typography>
				</MenuItem>
			</StyledMenu>

			<ConfirmDialog
				title={t('buttons.logout', { ns: 'core' })}
				isOpen={logoutConfirm}
				setIsOpen={(v) => setLogoutConfirm(v)}
				onConfirm={signoutRedirect}
			>
				<Typography>{t('auth.signout.confirm', { ns: 'core' })}</Typography>
			</ConfirmDialog>
		</Box>
	);
}
export default AppBarUser;
