import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function TransferVehicleSteps() {
	const { t } = useTranslation(['irp/supplements/transfer_vehicle', 'irp/supplements']);

	const { supplementKey } = useTypedParams(TransferVehiclePaths);

	const steps = [
		{
			label: t('details.step'),
			route: TransferVehiclePaths.Details.buildPath({ supplementKey }),
		},
		{
			label: t('verify.step', { ns: 'irp/supplements' }),
			route: TransferVehiclePaths.Verify.buildPath({ supplementKey }),
		},
		{
			label: t('submit.step', { ns: 'irp/supplements' }),
			route: TransferVehiclePaths.Submit.buildPath({ supplementKey }),
		},
	];

	return <SupplementSteps steps={steps} />;
}
