import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps, { SupplementStepProps } from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function NewAccountSteps() {
	const { t } = useTranslation(['irp', 'irp/supplements/new_account', 'irp/supplements', 'irp/fleets']);

	const { supplementKey } = useTypedParams(NewAccountPaths);

	const steps: SupplementStepProps['steps'] = [
		{
			label: t('registration.step', { ns: 'irp/supplements/new_account' }),
			route: NewAccountPaths.Registration.buildPath({ supplementKey }),
		},
		{
			label: t('details.title', { ns: 'irp/fleets' }),
			route: NewAccountPaths.Fleet.buildPath({ supplementKey }),
			activeRoutes: [
				NewAccountPaths.Fleet.Details.buildPath({ supplementKey }),
				NewAccountPaths.Fleet.Jurisdictions.buildPath({ supplementKey }),
			],
		},
		{
			label: t('weight_groups.step', { ns: 'irp/supplements' }),
			route: NewAccountPaths.WeightGroups.buildPath({ supplementKey }),
		},
		{
			label: t('vehicles.step', { ns: 'irp/supplements' }),
			route: NewAccountPaths.Vehicles.buildPath({ supplementKey }),
		},
		{
			label: t('documentation.step', { ns: 'irp/supplements' }),
			route: NewAccountPaths.Documentation.buildPath({ supplementKey }),
		},
		{ label: t('verify.step', { ns: 'irp/supplements' }), route: NewAccountPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('submit.step', { ns: 'irp/supplements' }), route: NewAccountPaths.Submit.buildPath({ supplementKey }) },
	];

	return <SupplementSteps steps={steps} />;
}
