import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

export default function AgencyUseOnlyDivider() {
	const { t } = useTranslation();

	return (
		<Divider sx={{ mt: 4, mb: 3 }}>
			<Chip label={t('user.type.use_only', { userType: t('user.type.jurisdiction') })} />
		</Divider>
	);
}
