import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import AddFleetRoutes from 'modules/irp/modules/supplements/modules/add_fleet/routes';
import AddVehicleRoutes from 'modules/irp/modules/supplements/modules/add_vehicle/routes';
import DeleteVehicleRoutes from 'modules/irp/modules/supplements/modules/delete_vehicle/routes';
import EditFleetRoutes from 'modules/irp/modules/supplements/modules/edit_fleet/routes';
import EditVehicleRoutes from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/index';
import NewAccountRoutes from 'modules/irp/modules/supplements/modules/new_account/routes';
import RenewalRoutes from 'modules/irp/modules/supplements/modules/renewal/routes';
import TransferVehicleRoutes from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/index';
import WeightGroupChangeRoutes from 'modules/irp/modules/supplements/modules/weight_group_change/routes';
import { SupplementProvider } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import RazorPaths from 'modules/razor/paths';
import { PropsWithChildren, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementIncludeFields } from 'types/Supplement';
import SupplementRouter from './SupplementRouter';
import SupplementPaths from './paths';

// Supplement provider from path params
export function SupplementProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(SupplementPaths.Supplement);

	const includes: SupplementIncludeFields = useMemo(() => ({ fleet: true }), []);

	return (
		<SupplementProvider supplementKey={params.supplementKey} includes={includes}>
			{children}
		</SupplementProvider>
	);
}

// Route component mappings
const SupplementsRoutes = [
	{
		path: SupplementPaths.path,
		element: (
			<CanAccess resource="irp.supplements" action={Actions.READ} denied={<NoPermission />}>
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements' }),
		},
		children: [
			// Index route
			{
				path: SupplementPaths.path,
				index: true,
				element: <Redirect to={RazorPaths.Manage.Supplements.path} replace />,
			} as Route<typeof SupplementPaths>,

			// Supplement path
			{
				path: SupplementPaths.Supplement.path,
				element: (
					<SupplementProviderFromPath>
						<Outlet />
					</SupplementProviderFromPath>
				),
				children: [
					// Redirect based on supplement type
					{
						path: SupplementPaths.Supplement.path,
						index: true,
						element: <SupplementRouter />,
					},

					// Sub-module routes
					...AddVehicleRoutes,
					...EditVehicleRoutes,
					...TransferVehicleRoutes,
					...DeleteVehicleRoutes,
					...WeightGroupChangeRoutes,
					...AddFleetRoutes,
					...EditFleetRoutes,
					...NewAccountRoutes,
					...RenewalRoutes,
				],
			},
		],
	} as Route<typeof SupplementPaths>,
];

export default SupplementsRoutes;
