import { Typography } from '@mui/material';
import { Code, PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import { useTranslation } from 'react-i18next';
import { route } from 'react-router-typesafe-routes/dom';

export const HelpPath = route('help');

export default function Help() {
	// Hooks
	const { t } = useTranslation();
	useTitle(`Help - ${t('title', { ns: 'core' })}`);

	return (
		<PageContainer>
			<Typography variant="h2" gutterBottom>
				Help
			</Typography>
			<Typography paragraph>
				This page can contain help information for the current module or the application as a whole. You can also
				redirect to another page or website.
			</Typography>
			<Typography paragraph>
				To enable it, set the <Code>helpRoute</Code> property of the <Code>{'<AppBar />'}</Code> component in{' '}
				<Code>src/App.tsx</Code>.
			</Typography>
			<Typography paragraph>
				Removing the <Code>helpRoute</Code> property will remove the help button from the app bar.
			</Typography>
		</PageContainer>
	);
}
